
import moduleLayout from "@/layouts/moduleLayout";
import {mapState, mapGetters} from "vuex";
import Design from "@/common/helpers/Design";
import ListValues from "@/components/Mixin/ListValues";

export default {
  name: "portalLayout",
  mixins: [ moduleLayout ],
  data: () => ({
    imagesBackgroundAppUrl: '',
  }),
  computed: {
    ...mapGetters("settings", ["designColorSchema", "designIcons", "designImages", "designLoaded"]),
    ...mapState(['startPageAfterLogin', 'portalViewName']),
    ...mapState(["portalViewHidden", 'portalCustomTabs', 'portalViewSettings']),
    ...mapGetters(["systemVariables"]),

    colorLoaded() {
      return this.designLoaded
    },
    iconsLoaded() {
      return this.designLoaded && Object.values(this.designIcons).length
    },
    showFormSettingLoaded() {
      return Boolean(this.portalViewHidden);
    },
    hiddenParts() {
      return this.portalViewHidden || {};
    },
    dynamicMenus() {
      return Object.values(this.portalCustomTabs || {})
        .map((a) => {
          a.id = a.module;
          return a;
        })
        .filter((a) => {
          return a.hidden !== true
        })
        .sort((a, b) => {
          return a.order - b.order;
      });
    },
  },
  methods: {
    getMenuPath(menu) {
      const path = menu.route
      ? ListValues.render(menu.route, this.systemVariables)
      : '/views/' + this.portalViewName + '/' + menu.id;
      
      return path;
    },
    setColorSchema() {
      if (this.colorLoaded) {
        Design.setColors()
      }
      this.imagesBackgroundAppUrl = this.designImages && this.designImages.backgroundApp ? this.designImages.backgroundApp.downloadableURL : '';
    },
    getItemName(item) {
      
      return item.pluralName || item.label || 'no name';
    },
    setViewSettings() {
      document.title = this.portalViewSettings.title;
    }
  },
  mounted() {
    this.setColorSchema();
    this.setViewSettings();
  },
  watch: {
    designColorSchema() {
      this.setColorSchema();
    }
  },
};
